import React from "react";
import PageLayout from "../components/pagelayout";
import SEO from "../components/seo";

const Contact = () => (
  <PageLayout>
    <SEO title="Contact" />
    <div id="form-container">
      <div id="contact-intro">
        <p className="paragraph">
          Feel free to drop me a message below, and I'll get back to you
        </p>
      </div>
      <form
        id="contact-form"
        name="Picker.tech Contact Form"
        method="POST"
        data-netlify="true"
        action="/confirmation"
      >
        <input
          type="hidden"
          name="form-name"
          value="Picker.tech Contact Form"
        />
        <label for="name" className="paragraph">
          Name
          <br />
          <input name="name" id="name" type="text" />
        </label>
        <br />
        <label for="email" className="paragraph">
          Email
          <br />
          <input name="email" id="email" type="email" />
        </label>
        <br />
        <label for="message" className="paragraph">
          Message
          <br />
          <textarea name="message" id="message"></textarea>
        </label>
        <br />
        <button class="button" type="submit" id="submit-button">
          Submit
        </button>
      </form>
    </div>
  </PageLayout>
);

export default Contact;
